
      import filterSitemap from "../defaults/filter.ts";

      
import { lazy } from 'react';

function populate(source) {
  const results = [];

  source.forEach(item => {
    const title = item.section;
    const [result] = results.filter(m => m.title === title);

    if (result) {
      result.links.push(item);
    } else {
      results.push({
        title,
        links: [item],
      });
    }
  });

  return results;
}


      export default filterSitemap({ "guidelines": {
      "title": "Guidelines",
      "sections": [...populate([
  {
    id: "01-introduction",
    route: "/guidelines/tutorials/01-introduction",
    title: "Introduction",
    link: "/guidelines/tutorials/01-introduction",
    section: "Getting Started",
    meta: {"title":"Introduction","description":"Find out what Piral is about. Learn more about the structure of our tutorials.","audience":"Everyone","level":"Beginner","section":"Getting Started"},
    page: lazy(() => import('../tools/__generated__/tutorials-01-introduction.en.tsx')),
  }, 
  {
    id: "02-getting-started",
    route: "/guidelines/tutorials/02-getting-started",
    title: "Getting Started",
    link: "/guidelines/tutorials/02-getting-started",
    section: "Getting Started",
    meta: {"title":"Getting Started","description":"Build your first application shell and add a pilet to it.","audience":"Architects, Developers","level":"Beginner","section":"Getting Started"},
    page: lazy(() => import('../tools/__generated__/tutorials-02-getting-started.en.tsx')),
  }, 
  {
    id: "03-publishing-pilets",
    route: "/guidelines/tutorials/03-publishing-pilets",
    title: "Publishing Pilets",
    link: "/guidelines/tutorials/03-publishing-pilets",
    section: "Getting Started",
    meta: {"title":"Publishing Pilets","description":"Publish your first pilet using the public feed service.","audience":"Architects, Developers","level":"Elementary","section":"Getting Started"},
    page: lazy(() => import('../tools/__generated__/tutorials-03-publishing-pilets.en.tsx')),
  }, 
  {
    id: "04-the-pilet-api",
    route: "/guidelines/tutorials/04-the-pilet-api",
    title: "The Pilet API",
    link: "/guidelines/tutorials/04-the-pilet-api",
    section: "Details",
    meta: {"title":"The Pilet API","description":"A closer look at pilets and the Pilet API.","audience":"Developers","level":"Elementary","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-04-the-pilet-api.en.tsx')),
  }, 
  {
    id: "05-customizing-the-api",
    route: "/guidelines/tutorials/05-customizing-the-api",
    title: "Customizing the API",
    link: "/guidelines/tutorials/05-customizing-the-api",
    section: "Details",
    meta: {"title":"Customizing the API","description":"Learn how to extend and customize the Pilet API.","audience":"Developers","level":"Intermediate","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-05-customizing-the-api.en.tsx')),
  }, 
  {
    id: "06-piral-layout",
    route: "/guidelines/tutorials/06-piral-layout",
    title: "Piral Layout",
    link: "/guidelines/tutorials/06-piral-layout",
    section: "Details",
    meta: {"title":"Piral Layout","description":"Set up the layout of your Piral instance.","audience":"Developers","level":"Intermediate","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-06-piral-layout.en.tsx')),
  }, 
  {
    id: "07-development-workflow",
    route: "/guidelines/tutorials/07-development-workflow",
    title: "Development Workflow",
    link: "/guidelines/tutorials/07-development-workflow",
    section: "Details",
    meta: {"title":"Development Workflow","description":"How development of a micro frontend with Piral will look in most cases.","audience":"Developers","level":"Intermediate","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-07-development-workflow.en.tsx')),
  }, 
  {
    id: "08-the-piral-cli",
    route: "/guidelines/tutorials/08-the-piral-cli",
    title: "The Piral CLI",
    link: "/guidelines/tutorials/08-the-piral-cli",
    section: "Details",
    meta: {"title":"The Piral CLI","description":"Details about the working with our tooling.","audience":"Developers","level":"Elementary","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-08-the-piral-cli.en.tsx')),
  }, 
  {
    id: "09-pilet-best-practices",
    route: "/guidelines/tutorials/09-pilet-best-practices",
    title: "Pilet Best Practices",
    link: "/guidelines/tutorials/09-pilet-best-practices",
    section: "Details",
    meta: {"title":"Pilet Best Practices","description":"Our recommendations for developing great pilets.","audience":"Architects, Developers","level":"Advanced","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-09-pilet-best-practices.en.tsx')),
  }, 
  {
    id: "10-mock-backend",
    route: "/guidelines/tutorials/10-mock-backend",
    title: "Mocking the Backend",
    link: "/guidelines/tutorials/10-mock-backend",
    section: "Details",
    meta: {"title":"Mocking the Backend","description":"How to develop offline-first with Piral.","audience":"Architects, Developers","level":"Advanced","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-10-mock-backend.en.tsx')),
  }, 
  {
    id: "11-server-side-rendering",
    route: "/guidelines/tutorials/11-server-side-rendering",
    title: "Server-Side Rendering",
    link: "/guidelines/tutorials/11-server-side-rendering",
    section: "Details",
    meta: {"title":"Server-Side Rendering","description":"How to improve startup performance with SSR.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-11-server-side-rendering.en.tsx')),
  }, 
  {
    id: "12-sharing-between-pilets",
    route: "/guidelines/tutorials/12-sharing-between-pilets",
    title: "Sharing Between Pilets",
    link: "/guidelines/tutorials/12-sharing-between-pilets",
    section: "Details",
    meta: {"title":"Sharing Between Pilets","description":"Pilets can share anything such as values, functions, or components.","audience":"Architects, Developers","level":"Intermediate","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-12-sharing-between-pilets.en.tsx')),
  }, 
  {
    id: "13-sharing-from-piral",
    route: "/guidelines/tutorials/13-sharing-from-piral",
    title: "Sharing From Piral",
    link: "/guidelines/tutorials/13-sharing-from-piral",
    section: "Details",
    meta: {"title":"Sharing From Piral","description":"How to share information from the Piral instance.","audience":"Architects, Developers","level":"Advanced","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-13-sharing-from-piral.en.tsx')),
  }, 
  {
    id: "14-converters",
    route: "/guidelines/tutorials/14-converters",
    title: "Converters",
    link: "/guidelines/tutorials/14-converters",
    section: "Details",
    meta: {"title":"Converters","description":"Piral allows multiple frameworks to work together using converters.","audience":"Architects, Developers","level":"Advanced","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-14-converters.en.tsx')),
  }, 
  {
    id: "15-share-dependencies",
    route: "/guidelines/tutorials/15-share-dependencies",
    title: "Sharing Dependencies",
    link: "/guidelines/tutorials/15-share-dependencies",
    section: "Details",
    meta: {"title":"Sharing Dependencies","description":"Dependencies can be shared in Piral implicitly and explicitly.","audience":"Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-15-share-dependencies.en.tsx')),
  }, 
  {
    id: "16-actions-and-state",
    route: "/guidelines/tutorials/16-actions-and-state",
    title: "Actions and State",
    link: "/guidelines/tutorials/16-actions-and-state",
    section: "Details",
    meta: {"title":"Actions and State","description":"How to use existing and new actions to manipulate the state.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-16-actions-and-state.en.tsx')),
  }, 
  {
    id: "17-events",
    route: "/guidelines/tutorials/17-events",
    title: "Events",
    link: "/guidelines/tutorials/17-events",
    section: "Details",
    meta: {"title":"Events","description":"Using events to communicate between pilets.","audience":"Developers","level":"Intermediate","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-17-events.en.tsx')),
  }, 
  {
    id: "18-authentication",
    route: "/guidelines/tutorials/18-authentication",
    title: "Authentication",
    link: "/guidelines/tutorials/18-authentication",
    section: "Details",
    meta: {"title":"Authentication","description":"How to use authentication with Piral.","audience":"Architects, Developers","level":"Intermediate","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-18-authentication.en.tsx')),
  }, 
  {
    id: "19-migrate-ssr",
    route: "/guidelines/tutorials/19-migrate-ssr",
    title: "Migration of SSR Applications",
    link: "/guidelines/tutorials/19-migrate-ssr",
    section: "Details",
    meta: {"title":"Migration of SSR Applications","description":"Discusses options for migration of existing websites.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-19-migrate-ssr.en.tsx')),
  }, 
  {
    id: "20-migrate-app",
    route: "/guidelines/tutorials/20-migrate-app",
    title: "Migration of Existing Applications",
    link: "/guidelines/tutorials/20-migrate-app",
    section: "Details",
    meta: {"title":"Migration of Existing Applications","description":"Illustrates how existing applications can be migrated conveniently.","audience":"Architects, Developers","level":"Advanced","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-20-migrate-app.en.tsx')),
  }, 
  {
    id: "21-parcel-vs-webpack",
    route: "/guidelines/tutorials/21-parcel-vs-webpack",
    title: "Parcel vs Webpack",
    link: "/guidelines/tutorials/21-parcel-vs-webpack",
    section: "Details",
    meta: {"title":"Parcel vs Webpack","description":"Helps to choose the right bundler for your application.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-21-parcel-vs-webpack.en.tsx')),
  }, 
  {
    id: "22-core-and-base",
    route: "/guidelines/tutorials/22-core-and-base",
    title: "Core and Base",
    link: "/guidelines/tutorials/22-core-and-base",
    section: "Details",
    meta: {"title":"Core and Base","description":"Guidance on using lower-level framework packages.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-22-core-and-base.en.tsx')),
  }, 
  {
    id: "23-monorepo",
    route: "/guidelines/tutorials/23-monorepo",
    title: "Monorepo",
    link: "/guidelines/tutorials/23-monorepo",
    section: "Details",
    meta: {"title":"Monorepo","description":"How to set up a monorepo with Piral.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-23-monorepo.en.tsx')),
  }, 
  {
    id: "23.1-monorepo-lerna",
    route: "/guidelines/tutorials/23.1-monorepo-lerna",
    title: "Lerna Monorepo",
    link: "/guidelines/tutorials/23.1-monorepo-lerna",
    section: "Details",
    meta: {"title":"Lerna Monorepo","description":"How to set up a monorepo with Piral and Lerna.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-23.1-monorepo-lerna.en.tsx')),
  }, 
  {
    id: "23.2-monorepo-rush",
    route: "/guidelines/tutorials/23.2-monorepo-rush",
    title: "Rush Monorepo",
    link: "/guidelines/tutorials/23.2-monorepo-rush",
    section: "Details",
    meta: {"title":"Rush Monorepo","description":"How to set up a monorepo with Piral and Rush.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-23.2-monorepo-rush.en.tsx')),
  }, 
  {
    id: "24-extension-patterns",
    route: "/guidelines/tutorials/24-extension-patterns",
    title: "Extension Patterns",
    link: "/guidelines/tutorials/24-extension-patterns",
    section: "Details",
    meta: {"title":"Extension Patterns","description":"How extensions can be used to create flexible frontends.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-24-extension-patterns.en.tsx')),
  }, 
  {
    id: "25-multiple-versions",
    route: "/guidelines/tutorials/25-multiple-versions",
    title: "Sharing Multiple Versions",
    link: "/guidelines/tutorials/25-multiple-versions",
    section: "Details",
    meta: {"title":"Sharing Multiple Versions","description":"Some strategies to share multiple versions of the same dependency.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-25-multiple-versions.en.tsx')),
  }, 
  {
    id: "26-multiple-app-shell",
    route: "/guidelines/tutorials/26-multiple-app-shell",
    title: "Developing for Multiple App Shells",
    link: "/guidelines/tutorials/26-multiple-app-shell",
    section: "Details",
    meta: {"title":"Developing for Multiple App Shells","description":"Guidance how pilets can be developed for multiple Piral instances.","audience":"Architects, Developers","level":"Proficient","section":"Details"},
    page: lazy(() => import('../tools/__generated__/tutorials-26-multiple-app-shell.en.tsx')),
  }]), {
        "id": "0Deployments",
        "title": "Deployments",
        "links": [
  {
    id: "0-overview",
    route: "/guidelines/deploy/0-overview",
    title: "Overview",
    link: "/guidelines/deploy/0-overview",
    section: "",
    meta: {"title":"Overview","description":"Overview of the deployment guides."},
    page: lazy(() => import('../tools/__generated__/deploy-0-overview.en.tsx')),
  }, 
  {
    id: "aws-amplify",
    route: "/guidelines/deploy/aws-amplify",
    title: "AWS Amplify",
    link: "/guidelines/deploy/aws-amplify",
    section: "",
    meta: {"title":"AWS Amplify","description":"How to deploy your Piral instance to the web using AWS."},
    page: lazy(() => import('../tools/__generated__/deploy-aws-amplify.en.tsx')),
  }, 
  {
    id: "buddy",
    route: "/guidelines/deploy/buddy",
    title: "Buddy",
    link: "/guidelines/deploy/buddy",
    section: "",
    meta: {"title":"Buddy","description":"How to deploy your Piral instance to the web using Buddy."},
    page: lazy(() => import('../tools/__generated__/deploy-buddy.en.tsx')),
  }, 
  {
    id: "cloudflare",
    route: "/guidelines/deploy/cloudflare",
    title: "Cloudflare Pages",
    link: "/guidelines/deploy/cloudflare",
    section: "",
    meta: {"title":"Cloudflare Pages","description":"How to deploy your Piral instance to the web using Cloudflare Pages."},
    page: lazy(() => import('../tools/__generated__/deploy-cloudflare.en.tsx')),
  }, 
  {
    id: "edgio",
    route: "/guidelines/deploy/edgio",
    title: "Edgio",
    link: "/guidelines/deploy/edgio",
    section: "",
    meta: {"title":"Edgio","description":"How to deploy your Piral instance to the web using Edgio."},
    page: lazy(() => import('../tools/__generated__/deploy-edgio.en.tsx')),
  }, 
  {
    id: "github",
    route: "/guidelines/deploy/github",
    title: "GitHub Pages",
    link: "/guidelines/deploy/github",
    section: "",
    meta: {"title":"GitHub Pages","description":"How to deploy your Piral instance to the web using GitHub Pages."},
    page: lazy(() => import('../tools/__generated__/deploy-github.en.tsx')),
  }, 
  {
    id: "gitlab",
    route: "/guidelines/deploy/gitlab",
    title: "GitLab Pages",
    link: "/guidelines/deploy/gitlab",
    section: "",
    meta: {"title":"GitLab Pages","description":"How to deploy your Piral instance to the web using GitLab Pages."},
    page: lazy(() => import('../tools/__generated__/deploy-gitlab.en.tsx')),
  }, 
  {
    id: "google-cloud",
    route: "/guidelines/deploy/google-cloud",
    title: "Google Cloud",
    link: "/guidelines/deploy/google-cloud",
    section: "",
    meta: {"title":"Google Cloud","description":"How to deploy your Piral instance to the web using Google Cloud."},
    page: lazy(() => import('../tools/__generated__/deploy-google-cloud.en.tsx')),
  }, 
  {
    id: "google-firebase",
    route: "/guidelines/deploy/google-firebase",
    title: "Google Firebase",
    link: "/guidelines/deploy/google-firebase",
    section: "",
    meta: {"title":"Google Firebase","description":"How to deploy your Piral instance to the web using Google's Firebase Hosting."},
    page: lazy(() => import('../tools/__generated__/deploy-google-firebase.en.tsx')),
  }, 
  {
    id: "heroku",
    route: "/guidelines/deploy/heroku",
    title: "Heroku",
    link: "/guidelines/deploy/heroku",
    section: "",
    meta: {"title":"Heroku","description":"How to deploy your Piral instance to the web using Heroku."},
    page: lazy(() => import('../tools/__generated__/deploy-heroku.en.tsx')),
  }, 
  {
    id: "ms-azure",
    route: "/guidelines/deploy/ms-azure",
    title: "Microsoft Azure",
    link: "/guidelines/deploy/ms-azure",
    section: "",
    meta: {"title":"Microsoft Azure","description":"How to deploy your Piral instance to the web using Microsoft Azure."},
    page: lazy(() => import('../tools/__generated__/deploy-ms-azure.en.tsx')),
  }, 
  {
    id: "netlify",
    route: "/guidelines/deploy/netlify",
    title: "Netlify",
    link: "/guidelines/deploy/netlify",
    section: "",
    meta: {"title":"Netlify","description":"How to deploy your Piral instance to the web on Netlify."},
    page: lazy(() => import('../tools/__generated__/deploy-netlify.en.tsx')),
  }, 
  {
    id: "render",
    route: "/guidelines/deploy/render",
    title: "Render",
    link: "/guidelines/deploy/render",
    section: "",
    meta: {"title":"Render","description":"How to deploy your Piral instance to the web using Render."},
    page: lazy(() => import('../tools/__generated__/deploy-render.en.tsx')),
  }, 
  {
    id: "vercel",
    route: "/guidelines/deploy/vercel",
    title: "Vercel",
    link: "/guidelines/deploy/vercel",
    section: "",
    meta: {"title":"Vercel","description":"How to deploy your Piral instance to the web on Vercel."},
    page: lazy(() => import('../tools/__generated__/deploy-vercel.en.tsx')),
  }],
      }, {
        "id": "1Example",
        "title": "Example",
        "links": [
  {
    id: "all-demos",
    route: "/guidelines/examples/all-demos",
    title: "All Demos",
    link: "/guidelines/examples/all-demos",
    section: "",
    meta: {"title":"All Demos"},
    page: lazy(() => import('../tools/__generated__/examples-all-demos.en.tsx')),
  }, 
  {
    id: "bare-shop",
    route: "/guidelines/examples/bare-shop",
    title: "Webshop",
    link: "/guidelines/examples/bare-shop",
    section: "",
    meta: {"title":"Webshop"},
    page: lazy(() => import('../tools/__generated__/examples-bare-shop.en.tsx')),
  }, 
  {
    id: "demo-core",
    route: "/guidelines/examples/demo-core",
    title: "Piral Core",
    link: "/guidelines/examples/demo-core",
    section: "",
    meta: {"title":"Piral Core"},
    page: lazy(() => import('../tools/__generated__/examples-demo-core.en.tsx')),
  }, 
  {
    id: "demo-cross",
    route: "/guidelines/examples/demo-cross",
    title: "Cross Frameworks",
    link: "/guidelines/examples/demo-cross",
    section: "",
    meta: {"title":"Cross Frameworks"},
    page: lazy(() => import('../tools/__generated__/examples-demo-cross.en.tsx')),
  }, 
  {
    id: "demo-full",
    route: "/guidelines/examples/demo-full",
    title: "Full Piral",
    link: "/guidelines/examples/demo-full",
    section: "",
    meta: {"title":"Full Piral"},
    page: lazy(() => import('../tools/__generated__/examples-demo-full.en.tsx')),
  }],
      }],
    },"concepts": {
      "title": "Concepts",
      "sections": [...populate([
  {
    id: "I01-architecture",
    route: "/concepts/I01-architecture",
    title: "Architecture",
    link: "/concepts/I01-architecture",
    section: "Internals",
    meta: {"title":"Architecture","description":"The architecture of the Piral framework for micro frontends.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I01-architecture.en.tsx')),
  }, 
  {
    id: "I02-libraries",
    route: "/concepts/I02-libraries",
    title: "Available Libraries",
    link: "/concepts/I02-libraries",
    section: "Internals",
    meta: {"title":"Available Libraries","description":"The available libraries and their purpose.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I02-libraries.en.tsx')),
  }, 
  {
    id: "I03-scaffolding",
    route: "/concepts/I03-scaffolding",
    title: "Scaffoling Process",
    link: "/concepts/I03-scaffolding",
    section: "Internals",
    meta: {"title":"Scaffoling Process","description":"The scaffolding process applied to a new Piral instance or pilet.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I03-scaffolding.en.tsx')),
  }, 
  {
    id: "I04-env-variables",
    route: "/concepts/I04-env-variables",
    title: "Environment Variables",
    link: "/concepts/I04-env-variables",
    section: "Internals",
    meta: {"title":"Environment Variables","description":"The used environment variables to determine some behavior of Piral.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I04-env-variables.en.tsx')),
  }, 
  {
    id: "I05-session-variables",
    route: "/concepts/I05-session-variables",
    title: "Session Variables",
    link: "/concepts/I05-session-variables",
    section: "Internals",
    meta: {"title":"Session Variables","description":"The used session variables to determine some behavior of Piral.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I05-session-variables.en.tsx')),
  }, 
  {
    id: "I06-debug-api",
    route: "/concepts/I06-debug-api",
    title: "Debug API",
    link: "/concepts/I06-debug-api",
    section: "Internals",
    meta: {"title":"Debug API","description":"The included API for debugging.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I06-debug-api.en.tsx')),
  }, 
  {
    id: "I07-pilet-lifecycle",
    route: "/concepts/I07-pilet-lifecycle",
    title: "Pilet Lifecycle",
    link: "/concepts/I07-pilet-lifecycle",
    section: "Internals",
    meta: {"title":"Pilet Lifecycle","description":"The lifecycle of a pilet.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I07-pilet-lifecycle.en.tsx')),
  }, 
  {
    id: "I08-importmap",
    route: "/concepts/I08-importmap",
    title: "Importmaps",
    link: "/concepts/I08-importmap",
    section: "Internals",
    meta: {"title":"Importmaps","description":"Using importmaps for sharing dependencies.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I08-importmap.en.tsx')),
  }, 
  {
    id: "I09-web-components",
    route: "/concepts/I09-web-components",
    title: "Web Components",
    link: "/concepts/I09-web-components",
    section: "Internals",
    meta: {"title":"Web Components","description":"Information about the used and available web components.","section":"Internals"},
    page: lazy(() => import('../tools/__generated__/concepts-I09-web-components.en.tsx')),
  }, 
  {
    id: "T01-emulator",
    route: "/concepts/T01-emulator",
    title: "Emulator",
    link: "/concepts/T01-emulator",
    section: "Tooling",
    meta: {"title":"Emulator","description":"Details on how an emulator is created and used.","section":"Tooling"},
    page: lazy(() => import('../tools/__generated__/concepts-T01-emulator.en.tsx')),
  }, 
  {
    id: "T02-bundlers",
    route: "/concepts/T02-bundlers",
    title: "Bundlers",
    link: "/concepts/T02-bundlers",
    section: "Tooling",
    meta: {"title":"Bundlers","description":"The idea and realization of integrating any bundler.","section":"Tooling"},
    page: lazy(() => import('../tools/__generated__/concepts-T02-bundlers.en.tsx')),
  }, 
  {
    id: "T03-templates",
    route: "/concepts/T03-templates",
    title: "Templates",
    link: "/concepts/T03-templates",
    section: "Tooling",
    meta: {"title":"Templates","description":"Details about the templates used for new pilets and Piral instances.","section":"Tooling"},
    page: lazy(() => import('../tools/__generated__/concepts-T03-templates.en.tsx')),
  }, 
  {
    id: "T04-cli-config",
    route: "/concepts/T04-cli-config",
    title: "CLI Configuration",
    link: "/concepts/T04-cli-config",
    section: "Tooling",
    meta: {"title":"CLI Configuration","description":"The possibilities of configuring the CLI locally and globally.","section":"Tooling"},
    page: lazy(() => import('../tools/__generated__/concepts-T04-cli-config.en.tsx')),
  }, 
  {
    id: "T05-kras-config",
    route: "/concepts/T05-kras-config",
    title: "Kras Configuration",
    link: "/concepts/T05-kras-config",
    section: "Tooling",
    meta: {"title":"Kras Configuration","description":"The possibilities of configuring the kras proxy server.","section":"Tooling"},
    page: lazy(() => import('../tools/__generated__/concepts-T05-kras-config.en.tsx')),
  }]), ...populate([
  {
    id: "00-meta",
    route: "/concepts/core-api/00-meta",
    title: "meta",
    link: "/concepts/core-api/00-meta",
    section: "Core Pilet API",
    meta: {"title":"meta","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-00-meta.en.tsx')),
  }, 
  {
    id: "01-register-extension",
    route: "/concepts/core-api/01-register-extension",
    title: "registerExtension",
    link: "/concepts/core-api/01-register-extension",
    section: "Core Pilet API",
    meta: {"title":"registerExtension","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-01-register-extension.en.tsx')),
  }, 
  {
    id: "02-unregister-extension",
    route: "/concepts/core-api/02-unregister-extension",
    title: "unregisterExtension",
    link: "/concepts/core-api/02-unregister-extension",
    section: "Core Pilet API",
    meta: {"title":"unregisterExtension","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-02-unregister-extension.en.tsx')),
  }, 
  {
    id: "03-register-page",
    route: "/concepts/core-api/03-register-page",
    title: "registerPage",
    link: "/concepts/core-api/03-register-page",
    section: "Core Pilet API",
    meta: {"title":"registerPage","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-03-register-page.en.tsx')),
  }, 
  {
    id: "04-unregister-page",
    route: "/concepts/core-api/04-unregister-page",
    title: "unregisterPage",
    link: "/concepts/core-api/04-unregister-page",
    section: "Core Pilet API",
    meta: {"title":"unregisterPage","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-04-unregister-page.en.tsx')),
  }, 
  {
    id: "05-set-data",
    route: "/concepts/core-api/05-set-data",
    title: "setData",
    link: "/concepts/core-api/05-set-data",
    section: "Core Pilet API",
    meta: {"title":"setData","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-05-set-data.en.tsx')),
  }, 
  {
    id: "06-get-data",
    route: "/concepts/core-api/06-get-data",
    title: "getData",
    link: "/concepts/core-api/06-get-data",
    section: "Core Pilet API",
    meta: {"title":"getData","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-06-get-data.en.tsx')),
  }, 
  {
    id: "07-extension",
    route: "/concepts/core-api/07-extension",
    title: "Extension",
    link: "/concepts/core-api/07-extension",
    section: "Core Pilet API",
    meta: {"title":"Extension","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-07-extension.en.tsx')),
  }, 
  {
    id: "08-render-html-extension",
    route: "/concepts/core-api/08-render-html-extension",
    title: "renderHtmlExtension",
    link: "/concepts/core-api/08-render-html-extension",
    section: "Core Pilet API",
    meta: {"title":"renderHtmlExtension","section":"Core Pilet API"},
    page: lazy(() => import('../tools/__generated__/core-api-08-render-html-extension.en.tsx')),
  }])],
    },"reference": {
      "title": "Reference",
      "sections": [...populate([
  {
    id: "C01-components",
    route: "/reference/documentation/C01-components",
    title: "Layout Components",
    link: "/reference/documentation/C01-components",
    section: "Customization",
    meta: {"title":"Layout Components","description":"Defining the error and layout components in a Piral instance.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C01-components.en.tsx')),
  }, 
  {
    id: "C02-errors",
    route: "/reference/documentation/C02-errors",
    title: "Error Handling",
    link: "/reference/documentation/C02-errors",
    section: "Customization",
    meta: {"title":"Error Handling","description":"The error handling methods of Piral.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C02-errors.en.tsx')),
  }, 
  {
    id: "C03-wrappers",
    route: "/reference/documentation/C03-wrappers",
    title: "Component Wrappers",
    link: "/reference/documentation/C03-wrappers",
    section: "Customization",
    meta: {"title":"Component Wrappers","description":"Details on the ability to define wrappers for registered components.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C03-wrappers.en.tsx')),
  }, 
  {
    id: "C11-loading-strategies",
    route: "/reference/documentation/C11-loading-strategies",
    title: "Loading Strategies",
    link: "/reference/documentation/C11-loading-strategies",
    section: "Customization",
    meta: {"title":"Loading Strategies","description":"The internal loading strategies and how to override them.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C11-loading-strategies.en.tsx')),
  }, 
  {
    id: "C12-debug-utils",
    route: "/reference/documentation/C12-debug-utils",
    title: "Debug Utilities",
    link: "/reference/documentation/C12-debug-utils",
    section: "Customization",
    meta: {"title":"Debug Utilities","description":"The debug utilities to allow further customizations during development.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C12-debug-utils.en.tsx')),
  }, 
  {
    id: "C21-piral-metadata",
    route: "/reference/documentation/C21-piral-metadata",
    title: "Piral Instance Metadata",
    link: "/reference/documentation/C21-piral-metadata",
    section: "Customization",
    meta: {"title":"Piral Instance Metadata","description":"The metadata defined for a Piral instance in its package.json.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C21-piral-metadata.en.tsx')),
  }, 
  {
    id: "C22-piral-validation",
    route: "/reference/documentation/C22-piral-validation",
    title: "Piral Validation",
    link: "/reference/documentation/C22-piral-validation",
    section: "Customization",
    meta: {"title":"Piral Validation","description":"The validate command with customization possibilities.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C22-piral-validation.en.tsx')),
  }, 
  {
    id: "C31-pilet-metadata",
    route: "/reference/documentation/C31-pilet-metadata",
    title: "Pilet Metadata",
    link: "/reference/documentation/C31-pilet-metadata",
    section: "Customization",
    meta: {"title":"Pilet Metadata","description":"The metadata defined for a pilet in its package.json.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C31-pilet-metadata.en.tsx')),
  }, 
  {
    id: "C32-pilet-validation",
    route: "/reference/documentation/C32-pilet-validation",
    title: "Pilet Validation",
    link: "/reference/documentation/C32-pilet-validation",
    section: "Customization",
    meta: {"title":"Pilet Validation","description":"The validate command with customization possibilities.","section":"Customization"},
    page: lazy(() => import('../tools/__generated__/documentation-C32-pilet-validation.en.tsx')),
  }, 
  {
    id: "U01-migration",
    route: "/reference/documentation/U01-migration",
    title: "Migration Instructions",
    link: "/reference/documentation/U01-migration",
    section: "Upgrade",
    meta: {"title":"Migration Instructions","description":"Find out what to do when upgrading from one major to another major version.","section":"Upgrade"},
    page: lazy(() => import('../tools/__generated__/documentation-U01-migration.en.tsx')),
  }, 
  {
    id: "U02-piral",
    route: "/reference/documentation/U02-piral",
    title: "Upgrading Piral Instances",
    link: "/reference/documentation/U02-piral",
    section: "Upgrade",
    meta: {"title":"Upgrading Piral Instances","description":"What needs to be done to upgrade Piral instances with newer versions of Piral.","section":"Upgrade"},
    page: lazy(() => import('../tools/__generated__/documentation-U02-piral.en.tsx')),
  }, 
  {
    id: "U03-pilet",
    route: "/reference/documentation/U03-pilet",
    title: "Upgrading Pilets",
    link: "/reference/documentation/U03-pilet",
    section: "Upgrade",
    meta: {"title":"Upgrading Pilets","description":"What needs to be done to upgrade pilets with newer app shell versions.","section":"Upgrade"},
    page: lazy(() => import('../tools/__generated__/documentation-U03-pilet.en.tsx')),
  }]), {
        "id": "0Specifications",
        "title": "Specifications",
        "links": [
  {
    id: "cli-specification",
    route: "/reference/specifications/cli-specification",
    title: "Piral CLI Specification",
    link: "/reference/specifications/cli-specification",
    section: "",
    meta: {"title":"Piral CLI Specification"},
    page: lazy(() => import('../tools/__generated__/specifications-cli-specification.en.tsx')),
  }, 
  {
    id: "feed-api-specification",
    route: "/reference/specifications/feed-api-specification",
    title: "Piral Feed Service API Specification",
    link: "/reference/specifications/feed-api-specification",
    section: "",
    meta: {"title":"Piral Feed Service API Specification"},
    page: lazy(() => import('../tools/__generated__/specifications-feed-api-specification.en.tsx')),
  }, 
  {
    id: "pilet-specification",
    route: "/reference/specifications/pilet-specification",
    title: "Pilet Package Specification",
    link: "/reference/specifications/pilet-specification",
    section: "",
    meta: {"title":"Pilet Package Specification"},
    page: lazy(() => import('../tools/__generated__/specifications-pilet-specification.en.tsx')),
  }],
      }, {
        "id": "1Framework Types",
        "title": "Framework Types",
        "links": [
  {
    id: "piral-base",
    route: "/reference/types/piral-base",
    title: "piral-base",
    link: "/reference/types/piral-base",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/types-piral-base.en.tsx')),
  }, 
  {
    id: "piral-core",
    route: "/reference/types/piral-core",
    title: "piral-core",
    link: "/reference/types/piral-core",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/types-piral-core.en.tsx')),
  }, 
  {
    id: "piral",
    route: "/reference/types/piral",
    title: "piral",
    link: "/reference/types/piral",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/types-piral.en.tsx')),
  }],
      }, {
        "id": "2Utility Types",
        "title": "Utility Types",
        "links": [
  {
    id: "piral-debug-utils",
    route: "/reference/types/piral-debug-utils",
    title: "piral-debug-utils",
    link: "/reference/types/piral-debug-utils",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/types-piral-debug-utils.en.tsx')),
  }, 
  {
    id: "piral-hooks-utils",
    route: "/reference/types/piral-hooks-utils",
    title: "piral-hooks-utils",
    link: "/reference/types/piral-hooks-utils",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/types-piral-hooks-utils.en.tsx')),
  }],
      }, {
        "id": "3More Information",
        "title": "More Information",
        "links": [
  {
    id: "articles",
    route: "/reference/documentation/articles",
    title: "Articles About Piral",
    link: "/reference/documentation/articles",
    section: "",
    meta: {"title":"Articles About Piral"},
    page: lazy(() => import('../tools/__generated__/documentation-articles.en.tsx')),
  }, 
  {
    id: "browsers",
    route: "/reference/documentation/browsers",
    title: "Browser Compatibility",
    link: "/reference/documentation/browsers",
    section: "",
    meta: {"title":"Browser Compatibility"},
    page: lazy(() => import('../tools/__generated__/documentation-browsers.en.tsx')),
  }, 
  {
    id: "features",
    route: "/reference/documentation/features",
    title: "Features and Alternatives",
    link: "/reference/documentation/features",
    section: "",
    meta: {"title":"Features and Alternatives"},
    page: lazy(() => import('../tools/__generated__/documentation-features.en.tsx')),
  }, 
  {
    id: "history",
    route: "/reference/documentation/history",
    title: "History",
    link: "/reference/documentation/history",
    section: "",
    meta: {"title":"History"},
    page: lazy(() => import('../tools/__generated__/documentation-history.en.tsx')),
  }],
      }],
    },"tooling": {
      "title": "Tooling",
      "sections": [...populate([
  {
    id: "add-piral-instance-pilet",
    route: "/tooling/add-piral-instance-pilet",
    title: "add-piral-instance-pilet",
    link: "/tooling/add-piral-instance-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-add-piral-instance-pilet.tsx')),
  }, 
  {
    id: "build-pilet",
    route: "/tooling/build-pilet",
    title: "build-pilet",
    link: "/tooling/build-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-build-pilet.tsx')),
  }, 
  {
    id: "build-piral",
    route: "/tooling/build-piral",
    title: "build-piral",
    link: "/tooling/build-piral",
    section: "Piral CLI - piral",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-build-piral.tsx')),
  }, 
  {
    id: "debug-pilet",
    route: "/tooling/debug-pilet",
    title: "debug-pilet",
    link: "/tooling/debug-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-debug-pilet.tsx')),
  }, 
  {
    id: "debug-piral",
    route: "/tooling/debug-piral",
    title: "debug-piral",
    link: "/tooling/debug-piral",
    section: "Piral CLI - piral",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-debug-piral.tsx')),
  }, 
  {
    id: "declaration-pilet",
    route: "/tooling/declaration-pilet",
    title: "declaration-pilet",
    link: "/tooling/declaration-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-declaration-pilet.tsx')),
  }, 
  {
    id: "declaration-piral",
    route: "/tooling/declaration-piral",
    title: "declaration-piral",
    link: "/tooling/declaration-piral",
    section: "Piral CLI - piral",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-declaration-piral.tsx')),
  }, 
  {
    id: "new-pilet",
    route: "/tooling/new-pilet",
    title: "new-pilet",
    link: "/tooling/new-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-new-pilet.tsx')),
  }, 
  {
    id: "new-piral",
    route: "/tooling/new-piral",
    title: "new-piral",
    link: "/tooling/new-piral",
    section: "Piral CLI - piral",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-new-piral.tsx')),
  }, 
  {
    id: "pack-pilet",
    route: "/tooling/pack-pilet",
    title: "pack-pilet",
    link: "/tooling/pack-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-pack-pilet.tsx')),
  }, 
  {
    id: "publish-pilet",
    route: "/tooling/publish-pilet",
    title: "publish-pilet",
    link: "/tooling/publish-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-publish-pilet.tsx')),
  }, 
  {
    id: "publish-piral",
    route: "/tooling/publish-piral",
    title: "publish-piral",
    link: "/tooling/publish-piral",
    section: "Piral CLI - piral",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-publish-piral.tsx')),
  }, 
  {
    id: "remove-piral-instance-pilet",
    route: "/tooling/remove-piral-instance-pilet",
    title: "remove-piral-instance-pilet",
    link: "/tooling/remove-piral-instance-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-remove-piral-instance-pilet.tsx')),
  }, 
  {
    id: "run-emulator-piral",
    route: "/tooling/run-emulator-piral",
    title: "run-emulator-piral",
    link: "/tooling/run-emulator-piral",
    section: "Piral CLI - piral",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-run-emulator-piral.tsx')),
  }, 
  {
    id: "upgrade-pilet",
    route: "/tooling/upgrade-pilet",
    title: "upgrade-pilet",
    link: "/tooling/upgrade-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-upgrade-pilet.tsx')),
  }, 
  {
    id: "upgrade-piral",
    route: "/tooling/upgrade-piral",
    title: "upgrade-piral",
    link: "/tooling/upgrade-piral",
    section: "Piral CLI - piral",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-upgrade-piral.tsx')),
  }, 
  {
    id: "validate-pilet",
    route: "/tooling/validate-pilet",
    title: "validate-pilet",
    link: "/tooling/validate-pilet",
    section: "Piral CLI - pilet",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-validate-pilet.tsx')),
  }, 
  {
    id: "validate-piral",
    route: "/tooling/validate-piral",
    title: "validate-piral",
    link: "/tooling/validate-piral",
    section: "Piral CLI - piral",
    meta: {},
    page: lazy(() => import('../tools/__generated__/commands-validate-piral.tsx')),
  }]), {
        "id": "0Others",
        "title": "Others",
        "links": [
  {
    id: "piral-inspector",
    route: "/tooling/piral-inspector",
    title: "Piral Inspector",
    link: "/tooling/piral-inspector",
    section: "",
    meta: {"title":"Piral Inspector"},
    page: lazy(() => import('../tools/__generated__/tools-piral-inspector.en.tsx')),
  }, 
  {
    id: "vscode-piral",
    route: "/tooling/vscode-piral",
    title: "Piral VS Code",
    link: "/tooling/vscode-piral",
    section: "",
    meta: {"title":"Piral VS Code"},
    page: lazy(() => import('../tools/__generated__/tools-vscode-piral.en.tsx')),
  }],
      }],
    },"plugins": {
      "title": "Plugins",
      "sections": [{
        "id": "0Overview",
        "title": "Overview",
        "links": [
  {
    id: "overview",
    route: "/plugins/overview",
    title: "All Plugins",
    link: "/plugins/overview",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/overview.tsx')),
  }],
      }, ...populate([
  {
    id: "piral-breadcrumbs",
    route: "/plugins/piral-breadcrumbs/:tab?",
    title: "piral-breadcrumbs",
    link: "/plugins/piral-breadcrumbs",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-breadcrumbs.tsx')),
  }, 
  {
    id: "piral-configs",
    route: "/plugins/piral-configs/:tab?",
    title: "piral-configs",
    link: "/plugins/piral-configs",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-configs.tsx')),
  }, 
  {
    id: "piral-containers",
    route: "/plugins/piral-containers/:tab?",
    title: "piral-containers",
    link: "/plugins/piral-containers",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-containers.tsx')),
  }, 
  {
    id: "piral-dashboard",
    route: "/plugins/piral-dashboard/:tab?",
    title: "piral-dashboard",
    link: "/plugins/piral-dashboard",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-dashboard.tsx')),
  }, 
  {
    id: "piral-feeds",
    route: "/plugins/piral-feeds/:tab?",
    title: "piral-feeds",
    link: "/plugins/piral-feeds",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-feeds.tsx')),
  }, 
  {
    id: "piral-forms",
    route: "/plugins/piral-forms/:tab?",
    title: "piral-forms",
    link: "/plugins/piral-forms",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-forms.tsx')),
  }, 
  {
    id: "piral-menu",
    route: "/plugins/piral-menu/:tab?",
    title: "piral-menu",
    link: "/plugins/piral-menu",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-menu.tsx')),
  }, 
  {
    id: "piral-modals",
    route: "/plugins/piral-modals/:tab?",
    title: "piral-modals",
    link: "/plugins/piral-modals",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-modals.tsx')),
  }, 
  {
    id: "piral-notifications",
    route: "/plugins/piral-notifications/:tab?",
    title: "piral-notifications",
    link: "/plugins/piral-notifications",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-notifications.tsx')),
  }, 
  {
    id: "piral-page-layouts",
    route: "/plugins/piral-page-layouts/:tab?",
    title: "piral-page-layouts",
    link: "/plugins/piral-page-layouts",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-page-layouts.tsx')),
  }, 
  {
    id: "piral-redux",
    route: "/plugins/piral-redux/:tab?",
    title: "piral-redux",
    link: "/plugins/piral-redux",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-redux.tsx')),
  }, 
  {
    id: "piral-search",
    route: "/plugins/piral-search/:tab?",
    title: "piral-search",
    link: "/plugins/piral-search",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-search.tsx')),
  }, 
  {
    id: "piral-tracker",
    route: "/plugins/piral-tracker/:tab?",
    title: "piral-tracker",
    link: "/plugins/piral-tracker",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-tracker.tsx')),
  }, 
  {
    id: "piral-update",
    route: "/plugins/piral-update/:tab?",
    title: "piral-update",
    link: "/plugins/piral-update",
    section: "component",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-update.tsx')),
  }, 
  {
    id: "piral-tracking",
    route: "/plugins/piral-tracking/:tab?",
    title: "piral-tracking",
    link: "/plugins/piral-tracking",
    section: "content",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-tracking.tsx')),
  }, 
  {
    id: "piral-translate",
    route: "/plugins/piral-translate/:tab?",
    title: "piral-translate",
    link: "/plugins/piral-translate",
    section: "content",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-translate.tsx')),
  }, 
  {
    id: "piral-aurelia",
    route: "/plugins/piral-aurelia/:tab?",
    title: "piral-aurelia",
    link: "/plugins/piral-aurelia",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-aurelia.tsx')),
  }, 
  {
    id: "piral-blazor",
    route: "/plugins/piral-blazor/:tab?",
    title: "piral-blazor",
    link: "/plugins/piral-blazor",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-blazor.tsx')),
  }, 
  {
    id: "piral-cycle",
    route: "/plugins/piral-cycle/:tab?",
    title: "piral-cycle",
    link: "/plugins/piral-cycle",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-cycle.tsx')),
  }, 
  {
    id: "piral-elm",
    route: "/plugins/piral-elm/:tab?",
    title: "piral-elm",
    link: "/plugins/piral-elm",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-elm.tsx')),
  }, 
  {
    id: "piral-ember",
    route: "/plugins/piral-ember/:tab?",
    title: "piral-ember",
    link: "/plugins/piral-ember",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-ember.tsx')),
  }, 
  {
    id: "piral-hyperapp",
    route: "/plugins/piral-hyperapp/:tab?",
    title: "piral-hyperapp",
    link: "/plugins/piral-hyperapp",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-hyperapp.tsx')),
  }, 
  {
    id: "piral-inferno",
    route: "/plugins/piral-inferno/:tab?",
    title: "piral-inferno",
    link: "/plugins/piral-inferno",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-inferno.tsx')),
  }, 
  {
    id: "piral-lazy",
    route: "/plugins/piral-lazy/:tab?",
    title: "piral-lazy",
    link: "/plugins/piral-lazy",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-lazy.tsx')),
  }, 
  {
    id: "piral-litel",
    route: "/plugins/piral-litel/:tab?",
    title: "piral-litel",
    link: "/plugins/piral-litel",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-litel.tsx')),
  }, 
  {
    id: "piral-million",
    route: "/plugins/piral-million/:tab?",
    title: "piral-million",
    link: "/plugins/piral-million",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-million.tsx')),
  }, 
  {
    id: "piral-mithril",
    route: "/plugins/piral-mithril/:tab?",
    title: "piral-mithril",
    link: "/plugins/piral-mithril",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-mithril.tsx')),
  }, 
  {
    id: "piral-ng",
    route: "/plugins/piral-ng/:tab?",
    title: "piral-ng",
    link: "/plugins/piral-ng",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-ng.tsx')),
  }, 
  {
    id: "piral-ngjs",
    route: "/plugins/piral-ngjs/:tab?",
    title: "piral-ngjs",
    link: "/plugins/piral-ngjs",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-ngjs.tsx')),
  }, 
  {
    id: "piral-preact",
    route: "/plugins/piral-preact/:tab?",
    title: "piral-preact",
    link: "/plugins/piral-preact",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-preact.tsx')),
  }, 
  {
    id: "piral-react",
    route: "/plugins/piral-react/:tab?",
    title: "piral-react",
    link: "/plugins/piral-react",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-react.tsx')),
  }, 
  {
    id: "piral-react-15",
    route: "/plugins/piral-react-15/:tab?",
    title: "piral-react-15",
    link: "/plugins/piral-react-15",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-react-15.tsx')),
  }, 
  {
    id: "piral-riot",
    route: "/plugins/piral-riot/:tab?",
    title: "piral-riot",
    link: "/plugins/piral-riot",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-riot.tsx')),
  }, 
  {
    id: "piral-solid",
    route: "/plugins/piral-solid/:tab?",
    title: "piral-solid",
    link: "/plugins/piral-solid",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-solid.tsx')),
  }, 
  {
    id: "piral-svelte",
    route: "/plugins/piral-svelte/:tab?",
    title: "piral-svelte",
    link: "/plugins/piral-svelte",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-svelte.tsx')),
  }, 
  {
    id: "piral-vue",
    route: "/plugins/piral-vue/:tab?",
    title: "piral-vue",
    link: "/plugins/piral-vue",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-vue.tsx')),
  }, 
  {
    id: "piral-vue-3",
    route: "/plugins/piral-vue-3/:tab?",
    title: "piral-vue-3",
    link: "/plugins/piral-vue-3",
    section: "converter",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-vue-3.tsx')),
  }, 
  {
    id: "piral-axios",
    route: "/plugins/piral-axios/:tab?",
    title: "piral-axios",
    link: "/plugins/piral-axios",
    section: "network",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-axios.tsx')),
  }, 
  {
    id: "piral-fetch",
    route: "/plugins/piral-fetch/:tab?",
    title: "piral-fetch",
    link: "/plugins/piral-fetch",
    section: "network",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-fetch.tsx')),
  }, 
  {
    id: "piral-urql",
    route: "/plugins/piral-urql/:tab?",
    title: "piral-urql",
    link: "/plugins/piral-urql",
    section: "network",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-urql.tsx')),
  }, 
  {
    id: "piral-adal",
    route: "/plugins/piral-adal/:tab?",
    title: "piral-adal",
    link: "/plugins/piral-adal",
    section: "user",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-adal.tsx')),
  }, 
  {
    id: "piral-auth",
    route: "/plugins/piral-auth/:tab?",
    title: "piral-auth",
    link: "/plugins/piral-auth",
    section: "user",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-auth.tsx')),
  }, 
  {
    id: "piral-oauth2",
    route: "/plugins/piral-oauth2/:tab?",
    title: "piral-oauth2",
    link: "/plugins/piral-oauth2",
    section: "user",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-oauth2.tsx')),
  }, 
  {
    id: "piral-oidc",
    route: "/plugins/piral-oidc/:tab?",
    title: "piral-oidc",
    link: "/plugins/piral-oidc",
    section: "user",
    meta: {},
    page: lazy(() => import('../tools/__generated__/plugin-piral-oidc.tsx')),
  }])],
    },"faq": {
      "title": "FAQ",
      "sections": [...populate([
  {
    id: "general",
    route: "/faq/general",
    title: "General Questions",
    link: "/faq/general",
    section: "",
    meta: {"title":"General Questions"},
    page: lazy(() => import('../tools/__generated__/questions-general.en.tsx')),
  }, 
  {
    id: "infrastructure",
    route: "/faq/infrastructure",
    title: "Infrastructure Questions",
    link: "/faq/infrastructure",
    section: "",
    meta: {"title":"Infrastructure Questions"},
    page: lazy(() => import('../tools/__generated__/questions-infrastructure.en.tsx')),
  }, 
  {
    id: "pilet-api",
    route: "/faq/pilet-api",
    title: "Pilet API Questions",
    link: "/faq/pilet-api",
    section: "",
    meta: {"title":"Pilet API Questions"},
    page: lazy(() => import('../tools/__generated__/questions-pilet-api.en.tsx')),
  }, 
  {
    id: "pilets",
    route: "/faq/pilets",
    title: "Pilet Questions",
    link: "/faq/pilets",
    section: "",
    meta: {"title":"Pilet Questions"},
    page: lazy(() => import('../tools/__generated__/questions-pilets.en.tsx')),
  }, 
  {
    id: "plugins",
    route: "/faq/plugins",
    title: "Plugin Questions",
    link: "/faq/plugins",
    section: "",
    meta: {"title":"Plugin Questions"},
    page: lazy(() => import('../tools/__generated__/questions-plugins.en.tsx')),
  }, 
  {
    id: "tooling",
    route: "/faq/tooling",
    title: "Tooling Questions",
    link: "/faq/tooling",
    section: "",
    meta: {"title":"Tooling Questions"},
    page: lazy(() => import('../tools/__generated__/questions-tooling.en.tsx')),
  }])],
    },"code": {
      "title": "",
      "sections": [...populate([
  {
    id: "0000",
    route: "/code/0000",
    title: "0000",
    link: "/code/0000",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0000.en.tsx')),
  }, 
  {
    id: "0001",
    route: "/code/0001",
    title: "0001",
    link: "/code/0001",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0001.en.tsx')),
  }, 
  {
    id: "0002",
    route: "/code/0002",
    title: "0002",
    link: "/code/0002",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0002.en.tsx')),
  }, 
  {
    id: "0003",
    route: "/code/0003",
    title: "0003",
    link: "/code/0003",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0003.en.tsx')),
  }, 
  {
    id: "0004",
    route: "/code/0004",
    title: "0004",
    link: "/code/0004",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0004.en.tsx')),
  }, 
  {
    id: "0010",
    route: "/code/0010",
    title: "0010",
    link: "/code/0010",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0010.en.tsx')),
  }, 
  {
    id: "0011",
    route: "/code/0011",
    title: "0011",
    link: "/code/0011",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0011.en.tsx')),
  }, 
  {
    id: "0012",
    route: "/code/0012",
    title: "0012",
    link: "/code/0012",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0012.en.tsx')),
  }, 
  {
    id: "0020",
    route: "/code/0020",
    title: "0020",
    link: "/code/0020",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0020.en.tsx')),
  }, 
  {
    id: "0021",
    route: "/code/0021",
    title: "0021",
    link: "/code/0021",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0021.en.tsx')),
  }, 
  {
    id: "0022",
    route: "/code/0022",
    title: "0022",
    link: "/code/0022",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0022.en.tsx')),
  }, 
  {
    id: "0023",
    route: "/code/0023",
    title: "0023",
    link: "/code/0023",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0023.en.tsx')),
  }, 
  {
    id: "0024",
    route: "/code/0024",
    title: "0024",
    link: "/code/0024",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0024.en.tsx')),
  }, 
  {
    id: "0025",
    route: "/code/0025",
    title: "0025",
    link: "/code/0025",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0025.en.tsx')),
  }, 
  {
    id: "0026",
    route: "/code/0026",
    title: "0026",
    link: "/code/0026",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0026.en.tsx')),
  }, 
  {
    id: "0027",
    route: "/code/0027",
    title: "0027",
    link: "/code/0027",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0027.en.tsx')),
  }, 
  {
    id: "0028",
    route: "/code/0028",
    title: "0028",
    link: "/code/0028",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0028.en.tsx')),
  }, 
  {
    id: "0029",
    route: "/code/0029",
    title: "0029",
    link: "/code/0029",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0029.en.tsx')),
  }, 
  {
    id: "0030",
    route: "/code/0030",
    title: "0030",
    link: "/code/0030",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0030.en.tsx')),
  }, 
  {
    id: "0031",
    route: "/code/0031",
    title: "0031",
    link: "/code/0031",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0031.en.tsx')),
  }, 
  {
    id: "0032",
    route: "/code/0032",
    title: "0032",
    link: "/code/0032",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0032.en.tsx')),
  }, 
  {
    id: "0040",
    route: "/code/0040",
    title: "0040",
    link: "/code/0040",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0040.en.tsx')),
  }, 
  {
    id: "0041",
    route: "/code/0041",
    title: "0041",
    link: "/code/0041",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0041.en.tsx')),
  }, 
  {
    id: "0042",
    route: "/code/0042",
    title: "0042",
    link: "/code/0042",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0042.en.tsx')),
  }, 
  {
    id: "0043",
    route: "/code/0043",
    title: "0043",
    link: "/code/0043",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0043.en.tsx')),
  }, 
  {
    id: "0044",
    route: "/code/0044",
    title: "0044",
    link: "/code/0044",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0044.en.tsx')),
  }, 
  {
    id: "0045",
    route: "/code/0045",
    title: "0045",
    link: "/code/0045",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0045.en.tsx')),
  }, 
  {
    id: "0046",
    route: "/code/0046",
    title: "0046",
    link: "/code/0046",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0046.en.tsx')),
  }, 
  {
    id: "0047",
    route: "/code/0047",
    title: "0047",
    link: "/code/0047",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0047.en.tsx')),
  }, 
  {
    id: "0048",
    route: "/code/0048",
    title: "0048",
    link: "/code/0048",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0048.en.tsx')),
  }, 
  {
    id: "0050",
    route: "/code/0050",
    title: "0050",
    link: "/code/0050",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0050.en.tsx')),
  }, 
  {
    id: "0051",
    route: "/code/0051",
    title: "0051",
    link: "/code/0051",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0051.en.tsx')),
  }, 
  {
    id: "0052",
    route: "/code/0052",
    title: "0052",
    link: "/code/0052",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0052.en.tsx')),
  }, 
  {
    id: "0053",
    route: "/code/0053",
    title: "0053",
    link: "/code/0053",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0053.en.tsx')),
  }, 
  {
    id: "0054",
    route: "/code/0054",
    title: "0054",
    link: "/code/0054",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0054.en.tsx')),
  }, 
  {
    id: "0060",
    route: "/code/0060",
    title: "0060",
    link: "/code/0060",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0060.en.tsx')),
  }, 
  {
    id: "0061",
    route: "/code/0061",
    title: "0061",
    link: "/code/0061",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0061.en.tsx')),
  }, 
  {
    id: "0062",
    route: "/code/0062",
    title: "0062",
    link: "/code/0062",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0062.en.tsx')),
  }, 
  {
    id: "0063",
    route: "/code/0063",
    title: "0063",
    link: "/code/0063",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0063.en.tsx')),
  }, 
  {
    id: "0064",
    route: "/code/0064",
    title: "0064",
    link: "/code/0064",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0064.en.tsx')),
  }, 
  {
    id: "0065",
    route: "/code/0065",
    title: "0065",
    link: "/code/0065",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0065.en.tsx')),
  }, 
  {
    id: "0066",
    route: "/code/0066",
    title: "0066",
    link: "/code/0066",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0066.en.tsx')),
  }, 
  {
    id: "0067",
    route: "/code/0067",
    title: "0067",
    link: "/code/0067",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0067.en.tsx')),
  }, 
  {
    id: "0068",
    route: "/code/0068",
    title: "0068",
    link: "/code/0068",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0068.en.tsx')),
  }, 
  {
    id: "0070",
    route: "/code/0070",
    title: "0070",
    link: "/code/0070",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0070.en.tsx')),
  }, 
  {
    id: "0071",
    route: "/code/0071",
    title: "0071",
    link: "/code/0071",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0071.en.tsx')),
  }, 
  {
    id: "0072",
    route: "/code/0072",
    title: "0072",
    link: "/code/0072",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0072.en.tsx')),
  }, 
  {
    id: "0073",
    route: "/code/0073",
    title: "0073",
    link: "/code/0073",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0073.en.tsx')),
  }, 
  {
    id: "0074",
    route: "/code/0074",
    title: "0074",
    link: "/code/0074",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0074.en.tsx')),
  }, 
  {
    id: "0075",
    route: "/code/0075",
    title: "0075",
    link: "/code/0075",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0075.en.tsx')),
  }, 
  {
    id: "0076",
    route: "/code/0076",
    title: "0076",
    link: "/code/0076",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0076.en.tsx')),
  }, 
  {
    id: "0077",
    route: "/code/0077",
    title: "0077",
    link: "/code/0077",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0077.en.tsx')),
  }, 
  {
    id: "0078",
    route: "/code/0078",
    title: "0078",
    link: "/code/0078",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0078.en.tsx')),
  }, 
  {
    id: "0080",
    route: "/code/0080",
    title: "0080",
    link: "/code/0080",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0080.en.tsx')),
  }, 
  {
    id: "0081",
    route: "/code/0081",
    title: "0081",
    link: "/code/0081",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0081.en.tsx')),
  }, 
  {
    id: "0082",
    route: "/code/0082",
    title: "0082",
    link: "/code/0082",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0082.en.tsx')),
  }, 
  {
    id: "0100",
    route: "/code/0100",
    title: "0100",
    link: "/code/0100",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0100.en.tsx')),
  }, 
  {
    id: "0101",
    route: "/code/0101",
    title: "0101",
    link: "/code/0101",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0101.en.tsx')),
  }, 
  {
    id: "0102",
    route: "/code/0102",
    title: "0102",
    link: "/code/0102",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0102.en.tsx')),
  }, 
  {
    id: "0110",
    route: "/code/0110",
    title: "0110",
    link: "/code/0110",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0110.en.tsx')),
  }, 
  {
    id: "0111",
    route: "/code/0111",
    title: "0111",
    link: "/code/0111",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0111.en.tsx')),
  }, 
  {
    id: "0112",
    route: "/code/0112",
    title: "0112",
    link: "/code/0112",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0112.en.tsx')),
  }, 
  {
    id: "0113",
    route: "/code/0113",
    title: "0113",
    link: "/code/0113",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0113.en.tsx')),
  }, 
  {
    id: "0114",
    route: "/code/0114",
    title: "0114",
    link: "/code/0114",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0114.en.tsx')),
  }, 
  {
    id: "0115",
    route: "/code/0115",
    title: "0115",
    link: "/code/0115",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0115.en.tsx')),
  }, 
  {
    id: "0116",
    route: "/code/0116",
    title: "0116",
    link: "/code/0116",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0116.en.tsx')),
  }, 
  {
    id: "0120",
    route: "/code/0120",
    title: "0120",
    link: "/code/0120",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0120.en.tsx')),
  }, 
  {
    id: "0121",
    route: "/code/0121",
    title: "0121",
    link: "/code/0121",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0121.en.tsx')),
  }, 
  {
    id: "0122",
    route: "/code/0122",
    title: "0122",
    link: "/code/0122",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0122.en.tsx')),
  }, 
  {
    id: "0123",
    route: "/code/0123",
    title: "0123",
    link: "/code/0123",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0123.en.tsx')),
  }, 
  {
    id: "0130",
    route: "/code/0130",
    title: "0130",
    link: "/code/0130",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0130.en.tsx')),
  }, 
  {
    id: "0161",
    route: "/code/0161",
    title: "0161",
    link: "/code/0161",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0161.en.tsx')),
  }, 
  {
    id: "0162",
    route: "/code/0162",
    title: "0162",
    link: "/code/0162",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0162.en.tsx')),
  }, 
  {
    id: "0163",
    route: "/code/0163",
    title: "0163",
    link: "/code/0163",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0163.en.tsx')),
  }, 
  {
    id: "0170",
    route: "/code/0170",
    title: "0170",
    link: "/code/0170",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0170.en.tsx')),
  }, 
  {
    id: "0171",
    route: "/code/0171",
    title: "0171",
    link: "/code/0171",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0171.en.tsx')),
  }, 
  {
    id: "0172",
    route: "/code/0172",
    title: "0172",
    link: "/code/0172",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0172.en.tsx')),
  }, 
  {
    id: "0173",
    route: "/code/0173",
    title: "0173",
    link: "/code/0173",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0173.en.tsx')),
  }, 
  {
    id: "0174",
    route: "/code/0174",
    title: "0174",
    link: "/code/0174",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0174.en.tsx')),
  }, 
  {
    id: "0175",
    route: "/code/0175",
    title: "0175",
    link: "/code/0175",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0175.en.tsx')),
  }, 
  {
    id: "0176",
    route: "/code/0176",
    title: "0176",
    link: "/code/0176",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0176.en.tsx')),
  }, 
  {
    id: "0180",
    route: "/code/0180",
    title: "0180",
    link: "/code/0180",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0180.en.tsx')),
  }, 
  {
    id: "0190",
    route: "/code/0190",
    title: "0190",
    link: "/code/0190",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0190.en.tsx')),
  }, 
  {
    id: "0200",
    route: "/code/0200",
    title: "0200",
    link: "/code/0200",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0200.en.tsx')),
  }, 
  {
    id: "0201",
    route: "/code/0201",
    title: "0201",
    link: "/code/0201",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0201.en.tsx')),
  }, 
  {
    id: "0202",
    route: "/code/0202",
    title: "0202",
    link: "/code/0202",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0202.en.tsx')),
  }, 
  {
    id: "0203",
    route: "/code/0203",
    title: "0203",
    link: "/code/0203",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0203.en.tsx')),
  }, 
  {
    id: "0204",
    route: "/code/0204",
    title: "0204",
    link: "/code/0204",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0204.en.tsx')),
  }, 
  {
    id: "0205",
    route: "/code/0205",
    title: "0205",
    link: "/code/0205",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0205.en.tsx')),
  }, 
  {
    id: "0206",
    route: "/code/0206",
    title: "0206",
    link: "/code/0206",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0206.en.tsx')),
  }, 
  {
    id: "0207",
    route: "/code/0207",
    title: "0207",
    link: "/code/0207",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0207.en.tsx')),
  }, 
  {
    id: "0301",
    route: "/code/0301",
    title: "0301",
    link: "/code/0301",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0301.en.tsx')),
  }, 
  {
    id: "0302",
    route: "/code/0302",
    title: "0302",
    link: "/code/0302",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0302.en.tsx')),
  }, 
  {
    id: "0303",
    route: "/code/0303",
    title: "0303",
    link: "/code/0303",
    section: "",
    meta: {},
    page: lazy(() => import('../tools/__generated__/messages-0303.en.tsx')),
  }])],
    } });
    